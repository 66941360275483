/* @flow */
/* global window, HTMLElement, IntersectionObserver, IntersectionObserverEntry, $FlowFixAny */

import React from 'react'
import type { Node, AbstractComponent } from 'react'

import debug from 'lib/debug'

if (typeof window !== 'undefined') {
  require('intersection-observer')
}

export type Props = {
  width: number,
  height: number,
  children: ?Node
}

type State = {
  isVisible: boolean
}

type Context = {
  observe: (HTMLElement, (void) => void) => void,
  unobserve: HTMLElement => void
}

const { Provider, Consumer } = React.createContext<Context | null>(null)

export default class LazyLoad extends React.Component<Props, State> {
  setVisible: void => void
  state: State
  _target: ?HTMLElement

  constructor (props: Props) {
    super(props)
    this.setVisible = this._setVisible.bind(this)
    this.state = {
      isVisible: false
    }
    this._target = null
  }

  _setVisible () {
    this.setState({
      isVisible: true
    })
  }

  _onRef (target: ?HTMLElement, context: Context) {
    if (target === this._target) {
      return
    }
    if (this._target) {
      context.unobserve(this._target)
    }
    if (target) {
      context.observe(target, this.setVisible)
    }
    this._target = target
  }

  render () {
    const { width, height, children } = this.props
    const { isVisible } = this.state

    const style = { width: `${width}px`, height: `${height}px` }
    if (isVisible) {
      return <div style={style}>{children}</div>
    }
    return (
      <Consumer>
        {(context: ?Context) => (
          <div
            ref={target => context && this._onRef(target, context)}
            style={style}
          />
        )}
      </Consumer>
    )
  }
}

export function withLazyLoad<P: {}> (
  Component: AbstractComponent<P>
): AbstractComponent<P> {
  return class WithLazyLoad extends React.Component<P> {
    onIntersection: (IntersectionObserverEntry[]) => void

    _container: $FlowFixAny
    _context: Context
    _observer: ?IntersectionObserver
    _callbacks: Map<HTMLElement, (void) => void>
    _pendingTargets: HTMLElement[]

    constructor (props: P) {
      super(props)

      this.onIntersection = this._onIntersection.bind(this)

      this._container = React.createRef()
      this._context = {
        observe: this._observe.bind(this),
        unobserve: this._unobserve.bind(this)
      }
      this._observer = null
      this._callbacks = new Map()
      this._pendingTargets = []
    }

    componentDidMount () {
      const observer = new window.IntersectionObserver(this.onIntersection, {
        root: this._container.current,
        rootMargin: '10000px'
      })
      this._observer = observer
      this._pendingTargets.forEach(target => observer.observe(target))
      this._pendingTargets = []
    }

    componentWillUnmount () {
      this._observer && this._observer.disconnect()
      this._observer = null
    }

    _onIntersection (entries: IntersectionObserverEntry[]) {
      entries.forEach(entry => {
        const { target, isIntersecting } = entry
        const onLazyLoad = this._callbacks.get(target)
        debug.assert(onLazyLoad)
        if (!onLazyLoad) {
          this._observer && this._observer.unobserve(target)
          return
        }
        if (isIntersecting) {
          onLazyLoad()
        }
      })
    }

    _observe (target: HTMLElement, onLazyLoad: void => void) {
      debug.assert(!this._callbacks.has(target))
      this._callbacks.set(target, onLazyLoad)
      if (this._observer) {
        this._observer.observe(target)
      } else {
        this._pendingTargets.push(target)
      }
    }

    _unobserve (target: HTMLElement) {
      debug.assert(this._callbacks.has(target))
      this._callbacks.delete(target)
      this._observer && this._observer.unobserve(target)
    }

    render () {
      return (
        <Provider value={this._context} ref={this._container}>
          <Component {...this.props} />
        </Provider>
      )
    }
  }
}
