/* @flow */

import React from 'react'

export type Props = {
  src: string,
  href?: string,
  width: number,
  height: number,
  title?: string
}

export default ({ src, href, width, height, title }: Props) => {
  const style = {
    width: `${width}px`,
    height: `${height}px`
  }
  const img = <img src={src} title={title} style={style} />
  return href
    ? <a href={href} title={title}>{img}</a>
    : img
}
