/* @flow */
/* global $FlowFixAny */

import React from 'react'
import ReactDOM from 'react-dom'
import ReactDOMServer from 'react-dom/server'

import type { Props as PictureProps } from 'components/picture'

import App from './app'
import './main.less'

type DatedPicture = PictureProps & {
  +date: string,
  +weight: number
}

// $FlowFixMe
const req = require.context('pictures', false, /\.jpg$/)

const pictures = req
  .keys()
  .map(key => ({ key: key.substr(2), ...req(key) }))
  .sort((a: DatedPicture, b: DatedPicture) => {
    if (a.date < b.date) {
      return -1
    }
    if (a.date > b.date) {
      return +1
    }
    return b.weight - a.weight
  })
  .reverse()

const Root = () => <App pictures={pictures} />

if (typeof document !== 'undefined') {
  const root = document.getElementById('root')
  if (root) {
    ReactDOM.hydrate(<Root />, root)
  }
}

export default (locals: $FlowFixAny, callback: $FlowFixAny) => {
  // locals.assets only contain javascript assets. we want all of them!
  const assets = getAssetPaths(locals.webpackStats, 'main') // same main as in webpack.config.js' entry
  const scripts = assets.filter(asset => /\.jsx?$/.test(asset))
  const stylesheets = assets.filter(asset => /\.css$/.test(asset))

  const root = ReactDOMServer.renderToString(<Root location={locals.path} />)

  const html = ReactDOMServer.renderToStaticMarkup(
    <html lang='en'>
      <head>
        <meta charSet='utf-8' />
        <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <title>Bram de Greve Photography</title>
        {React.Children.map(stylesheets, asset => (
          <link rel='stylesheet' type='text/css' href={asset} />
        ))}
      </head>
      <body>
        <div id='root' dangerouslySetInnerHTML={{ __html: root }} />
        {React.Children.map(scripts, asset => (
          <script type='text/javascript' src={asset} />
        ))}
      </body>
    </html>
  )

  return callback(null, `<!DOCTYPE html>\n${html}`)
}

function getAssetPaths (webpackStats, chunkName) {
  const stats = webpackStats.toJson({
    chunks: false,
    modules: false,
    source: false,
    errorDetails: false,
    timings: false
  })
  const assets = chunkName
    ? stats.assetsByChunkName[chunkName]
    : stats.assets.map(asset => asset.name)
  let publicPath = stats.publicPath
  if (publicPath.length && !publicPath.endsWith('/')) {
    publicPath += '/'
  }
  return assets.map(asset => `${publicPath}${asset}`)
}
