/* @flow */

type Arg = ?string | false | (() => Arg) | { [string]: ?string } | Arg[]

export default (...args: Arg[]): string => classNames(args)

function classNames (arg: Arg): string {
  if (Array.isArray(arg)) {
    return arg
      .map(classNames)
      .filter(_ => _)
      .join(' ')
  }
  if (typeof arg === 'object' && arg !== null) {
    const a = arg // working around type system limitations.
    return Object.keys(arg)
      .filter(key => a[key])
      .join(' ')
  }
  if (typeof arg === 'function') {
    return classNames(arg())
  }
  return arg || ''
}
