/* @flow */

import React from 'react'

import Header from 'components/header'
import Footer from 'components/footer'
import PictureList from 'components/picture-list'
import type { Props } from 'components/picture-list'
import withSize from 'components/with-size'

import styles from './app.less'

type _Props = Props & {
  +clientWidth: number,
  +clientHeight: number
}

export default withSize(
  // $FlowFixMe
  React.forwardRef(({ pictures, clientWidth, clientHeight }: _Props, ref) => (
    <div ref={ref} className={styles.container}>
      <header className={styles.header}>
        <Header viewWidth={clientWidth} />
      </header>
      <main className={styles.main}>
        <PictureList
          pictures={pictures}
          viewWidth={clientWidth}
          viewHeight={clientHeight}
        />
      </main>
      <footer className={styles.footer}>
        <Footer viewWidth={clientWidth} />
      </footer>
    </div>
  ))
)
