/* @flow */

import React from 'react'

import type { SizedImage } from 'types'
import ImageReplacement from 'components/image-replacement'

export type Props = {
  +viewWidth?: number
}

const IMG_HEADER: SizedImage = (require('img/bramdegreve.gif'): any)
const MARGIN = 20 // px

export default ({ viewWidth }: Props) => {
  let { width, height } = IMG_HEADER
  let margin = MARGIN
  const totalWidth = width + 2 * margin
  if (viewWidth && totalWidth > viewWidth) {
    const scale = viewWidth / totalWidth
    width = Math.floor(width * scale)
    height = Math.floor(height * scale)
    margin = Math.floor(margin * scale)
  }
  return (
    <div
      style={{ margin: `${2 * margin}px ${margin}px ${margin}px ${margin}px` }}
    >
      <ImageReplacement src={IMG_HEADER.src} width={width} height={height}>
        <h1>Bram de Greve Photography</h1>
      </ImageReplacement>
    </div>
  )
}
