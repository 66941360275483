/* @flow */

export function log (...args: any[]) {
  if (process.env.NODE_ENV !== 'production') {
    console.log(...args)
  }
}

export function assert (...args: any[]) {
  if (process.env.NODE_ENV !== 'production') {
    console.assert(...args)
  }
}

export default {
  log,
  assert
}
