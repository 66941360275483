/* @flow */
/* global SyntheticTouchEvent, HTMLElement */

import React from 'react'
import type { ComponentType } from 'react'

import { withHocDisplayName } from 'lib/display-name'
import debug from 'lib/debug'

export type Direction = null | 'up' | 'right' | 'down' | 'left'

export type OnSwipe = (Direction) => void

type TouchProps = {
  +onSwipe?: OnSwipe
}

type EventType = 'start' | 'move' | 'end' | 'cancel'

type Processor = {
  +process: (SyntheticTouchEvent<HTMLElement>, EventType, number) => boolean
}

class Swipe {
  +onSwipe: OnSwipe
  state: ?{
    startX: number,
    startY: number,
    prevX: number,
    prevY: number,
    prevTime: number,
    direction: Direction
  }

  constructor (onSwipe: OnSwipe) {
    this.onSwipe = onSwipe
    this.state = null
  }
  process (ev: SyntheticTouchEvent<HTMLElement>, type: EventType, time: number) {
    const { screenX, screenY } = ev.changedTouches[0]
    debug.log(time, type, ev.touches.length, screenX, screenY)
    if (ev.touches.length !== (type === 'end' ? 0 : 1)) {
      debug.log('wrong number of touches')
      return false
    }
    if (!this.state) {
      this.state = {
        startX: screenX,
        startY: screenY,
        prevX: screenX,
        prevY: screenY,
        prevTime: time,
        direction: null
      }
      debug.log('started')
      return true
    }
    const state = this.state
    // const dx = screenX - state.prevX
    // const dy = screenX - state.prevY
    // const dt = time - state.prevTime
    // const sqrSpeed = (dx * dx + dy * dy) / (dt * dt)
    // if (sqrSpeed < 5) {
    //  debug.log('too slow', sqrSpeed)
    //  return false
    // }
    const dir = direction(screenX - state.startX, screenY - state.startY)
    if (state.direction === null) {
      state.direction = dir
    } else if (dir && dir !== state.direction) {
      debug.log('wrong direction', dir, state.direction)
      return false
    }
    state.prevX = screenX
    state.prevY = screenX
    state.prevTime = time
    if (type === 'end') {
      debug.log('SWIPE', state.direction)
      setTimeout(() => { this.onSwipe(dir) }, 0)
      return false
    }
    return true
  }
}

export default function withGestures<Props: {}> (
  Component: ComponentType<Props>
): ComponentType<Props & TouchProps> {
  class Gestures extends React.Component<Props & TouchProps, void> {
    onTouchStart: (SyntheticTouchEvent<HTMLElement>) => void
    onTouchMove: (SyntheticTouchEvent<HTMLElement>) => void
    onTouchEnd: (SyntheticTouchEvent<HTMLElement>) => void
    onTouchCancel: (SyntheticTouchEvent<HTMLElement>) => void
    processors: ?Processor[]

    constructor (props: Props & TouchProps) {
      super(props)

      this.onTouchStart = this._onTouchStart.bind(this)
      this.onTouchMove = this._onTouchMove.bind(this)
      this.onTouchEnd = this._onTouchEnd.bind(this)
      this.onTouchCancel = this._onTouchCancel.bind(this)

      this.processors = null
    }

    _onTouchStart (ev: SyntheticTouchEvent<HTMLElement>) {
      if (this.processors === null) {
        const { onSwipe } = this.props
        this.processors = []
        if (onSwipe) {
          this.processors.push(new Swipe(onSwipe))
        }
      }
      this._process(ev, 'start')
    }

    _onTouchMove (ev: SyntheticTouchEvent<HTMLElement>) {
      this._process(ev, 'move')
    }

    _onTouchEnd (ev: SyntheticTouchEvent<HTMLElement>) {
      this._process(ev, 'end')
    }

    _onTouchCancel (ev: SyntheticTouchEvent<HTMLElement>) {
      this._process(ev, 'cancel')
    }

    render () {
      const { onSwipe, ...rest } = this.props
      return (
        <Component
          onTouchStart={this.onTouchStart}
          onTouchMove={this.onTouchMove}
          onTouchEnd={this.onTouchEnd}
          onTouchCancel={this.onTouchCancel}
          {...rest}
        />
      )
    }

    _process (ev: SyntheticTouchEvent<HTMLElement>, type: EventType) {
      debug.assert(this.processors !== null)
      const time = Date.now()
      if (this.processors) {
        this.processors = this.processors.filter(obj =>
          obj.process(ev, type, time)
        )
        if (ev.touches.length === 0) {
          debug.assert(this.processors.length === 0)
          this.processors = null
        }
      }
    }
  }

  return withHocDisplayName(Gestures, Component)
}

function direction (dx, dy): Direction {
  const ax = Math.abs(dx)
  const ay = Math.abs(dy)
  if (ax > ay) {
    return dx > 0 ? 'right' : 'left'
  } else if (ay > ax) {
    return dy > 0 ? 'down' : 'up'
  }
  return null
}
