/* @flow */
/* global KeyboardEvent, $ReadOnly */

import React from 'react'
import type { ComponentType } from 'react'

import type { Props } from 'components/picture-list'
import withHiddenMeat from 'components/hidden-meat'
import withGestures from 'components/with-gestures'
import type { Direction } from 'components/with-gestures'

import { replaceHash, getHash } from 'lib/history'
import classNames from 'lib/class-names'
import { withHocDisplayName } from 'lib/display-name'
import debug from 'lib/debug'

import styles from './styles.less'

type State = {
  highlight: number
}

export default function withNavigation (
  Component: ComponentType<Props & $ReadOnly<State>>
): ComponentType<Props> {
  class WithNavigation extends React.Component<Props, State> {
    state: State
    onKeyDown: KeyboardEvent => void
    onSwipe: Direction => void
    onHashChange: void => void
    goLeft: void => void
    goRight: void => void
    goHome: void => void
    goEnd: void => void

    constructor (props: Props) {
      super(props)

      this.state = {
        highlight: 0
      }

      this.onKeyDown = this._onKeyDown.bind(this)
      this.onSwipe = this._onSwipe.bind(this)
      this.onHashChange = this._onChangeHash.bind(this)

      this.goLeft = this._goLeft.bind(this)
      this.goRight = this._goRight.bind(this)
      this.goHome = this._goHome.bind(this)
      this.goEnd = this._goEnd.bind(this)
    }

    componentDidMount () {
      window.addEventListener('keydown', this.onKeyDown, false)
      window.addEventListener('hashchange', this.onHashChange, false)
      this.onHashChange()
    }

    componentWillUnmount () {
      window.removeEventListener('keydown', this.onKeyDown)
      window.removeEventListener('hashchange', this.onHashChange)
    }

    componentWillUpdate (nextProps: Props, nextState: State) {
      const { highlight } = nextState
      const picture = nextProps.pictures[highlight]
      if (!picture) {
        return
      }
      replaceHash(picture.key)
    }

    _onChangeHash () {
      const hash = getHash()
      if (hash === null) {
        return
      }
      const highlight = this.props.pictures.findIndex(x => x.key === hash)
      if (highlight < 0) {
        return
      }
      this.setState({
        highlight
      })
    }

    _onKeyDown (ev: KeyboardEvent) {
      switch (ev.key) {
        case 'ArrowLeft':
        case 'Left':
          return this.goLeft()
        case 'ArrowRight':
        case 'Right':
          return this.goRight()
        case 'Home':
          return this.goHome()
        case 'End':
          return this.goEnd()
      }
    }

    _onSwipe (dir: Direction) {
      debug.log('--- been here', dir)
      switch (dir) {
        case 'left':
          return this.goRight()
        case 'right':
          return this.goLeft()
      }
    }

    _goLeft () {
      this.setState(({ highlight }) => ({
        highlight: Math.max(0, highlight - 1)
      }))
    }

    _goRight () {
      this.setState(({ highlight }, { pictures }) => ({
        highlight: Math.min(pictures.length - 1, highlight + 1)
      }))
    }

    _goHome () {
      this.setState({
        highlight: 0
      })
    }

    _goEnd () {
      this.setState((_, { pictures }) => ({
        highlight: pictures.length - 1
      }))
    }

    render () {
      const { pictures, viewWidth, viewHeight, ...rest } = this.props
      const { highlight } = this.state

      // hacky casting business for flow...
      const leftArrow: Image = (require('img/left-arrow.png'): any)
      const rightArrow: Image = (require('img/right-arrow.png'): any)

      return (
        <Gestures
          onSwipe={this.onSwipe}
          className={styles.navigation}
          {...rest}
        >
          <Component
            pictures={pictures}
            viewWidth={viewWidth}
            viewHeight={viewHeight}
            highlight={highlight}
          />
          <Button
            title='go left'
            onClick={this.goLeft}
            className={styles.leftArrow}
            {...leftArrow}
            hidden={this.state.highlight <= 0}
          />
          <Button
            title='go right'
            onClick={this.goRight}
            className={styles.rightArrow}
            {...rightArrow}
            hidden={this.state.highlight >= this.props.pictures.length - 1}
          />
        </Gestures>
      )
    }
  }

  return withHocDisplayName(WithNavigation, Component)
}

type Image = {
  +src: string,
  +width: number,
  +height: number
}

type ButtonProps = {
  +src: string,
  +width: number,
  +height: number,
  +title?: string,
  +className?: string
}

const Button = withHiddenMeat(
  ({ title, src, width, height, className, ...rest }: ButtonProps) => (
    <div
      className={classNames(styles.button, className)}
      style={{ width: `${width + 24}px` }}
      {...rest}
    >
      <img
        src={src}
        title={title}
        style={{
          width: `${width}px`,
          height: `${height}px`
        }}
      />
    </div>
  )
)

const Gestures = withGestures(({ children, ...rest }) => (
  <div {...rest}>{children}</div>
))
