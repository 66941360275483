/* @flow */

import React from 'react'
import type { Node } from 'react'

import styles from './styles.less'

export type Props = {
  +src: string,
  +width: number,
  +height: number,
  +children?: Node
}

export default ({ src, width, height, children }: Props) => (
  <div
    className={styles.container}
    style={{
      width: `${width}px`,
      height: `${height}px`
    }}
  >
    <span
      className={styles.image}
      style={{
        backgroundImage: `url(${src})`
      }}
    />
    {children}
  </div>
)
