/* @flow */

import React from 'react'

import type { SizedImage } from 'types'
import NavLink from 'components/nav-link'
import Year from 'components/year'
import type { Props as NavLinkProps } from 'components/nav-link'

import styles from './styles.less'

export type Props = {
  +className?: string,
  +viewWidth?: number
}

const LINKS: NavLinkProps[] = [
  {
    ...((require('img/gallery.gif'): any): SizedImage),
    href: '//gallery.bramz.net/',
    title: 'gallery'
  },
  {
    ...((require('img/blog.gif'): any): SizedImage),
    href: '//www.bramz.net/',
    title: 'blog'
  },
  {
    ...((require('img/facebook.gif'): any): SizedImage),
    href: '//www.facebook.com/bramdegrevephotography',
    title: 'facebook'
  },
  {
    ...((require('img/indiestyle.gif'): any): SizedImage),
    href: 'http://www.indiestyle.be/',
    title: 'indiestyle'
  }
]

const MARGIN = 20

const TOTAL_WIDTH = LINKS.reduce(
  (total, { width }) => total + width + MARGIN,
  MARGIN
)

export default ({ className, viewWidth }: Props) => {
  const scale = viewWidth && viewWidth < TOTAL_WIDTH ? viewWidth / TOTAL_WIDTH : 1
  const margin = `${Math.floor(MARGIN * scale)}px`
  const halfMargin = `${Math.floor(MARGIN * scale / 2)}px`
  return (
    <React.Fragment>
      <nav className={styles.nav} style={{ margin: `${halfMargin} ${margin}` }}>
        <ul>
          {LINKS.map(({ width, height, ...rest }: NavLinkProps, index) => (
            <li
              key={index}
              style={{ marginLeft: index === 0 ? 0 : margin }}
            >
              {/* $FlowFixMe  apparently flow cannot figure out type of rest */}
              <NavLink
                width={Math.floor(width * scale)}
                height={Math.floor(height * scale)}
                {...rest}
              />
            </li>
          ))}
        </ul>
      </nav>
      <p
        className={styles.address}
        style={{ margin: `${halfMargin} ${margin}` }}
      >
        Copyright &copy;&nbsp;2007&mdash;<Year />{' '}
        <a href='mailto:bram@bramdegreve.com'>Bram de Greve</a>. All rights
        reserved. BTW&nbsp;BE&nbsp;0830.943.867
      </p>
    </React.Fragment>
  )
}
