/* @flow */

import React from 'react'

import Picture from 'components/picture'
import LazyLoad, { withLazyLoad } from 'components/lazy-load'
import type { Props as PictureProps } from 'components/picture'
import classNames from 'lib/class-names'

import withNavigation from './navigation'
import styles from './styles.less'

type _PictureProps = PictureProps & {
  +key: string
}

export type Props = {
  +pictures: _PictureProps[],
  +viewWidth?: number,
  +viewHeight?: number
}

type _Props = Props & {
  +highlight: number
}

type State = {
  positions: number[],
  totalWidth: number,
  maxWidth: number,
  maxHeight: number
}

const PADDING = 12

class PictureLists extends React.Component<_Props, State> {
  state: State

  constructor (props: _Props) {
    super(props)
    this.state = _computeState(props)
  }

  componentWillReceiveProps (nextProps: Props) {
    if (nextProps.pictures !== this.props.pictures) {
      this.setState(_computeState(nextProps))
    }
  }

  render () {
    const { pictures, highlight, viewWidth, viewHeight } = this.props
    const { positions, totalWidth, maxWidth, maxHeight } = this.state
    const scale = Math.min(
      1,
      viewWidth ? viewWidth / maxWidth : 1,
      viewHeight ? viewHeight / maxHeight : 1
    )
    const translate = -(positions[highlight] + pictures[highlight].width / 2)
    const padding = `${PADDING * scale}px`
    return (
      <div
        style={{
          transform: `translateX(${Math.ceil(translate * scale)}px)`,
          width: `calc(100vw + ${Math.ceil(totalWidth * scale)}px)`
        }}
        className={styles.container}
      >
        {pictures.map(({ key, width, height, ...rest }, index) => (
          <article
            key={key}
            className={classNames(
              styles.picture,
              index === highlight && styles.highlight
            )}
            style={{ padding }}
          >
            <LazyLoad width={width * scale} height={height * scale}>
              <Picture
                width={width * scale}
                height={height * scale}
                {...rest}
              />
            </LazyLoad>
          </article>
        ))}
      </div>
    )
  }
}

export default withLazyLoad<Props>(withNavigation(PictureLists))

function _computeState (props: Props): State {
  return props.pictures.reduce(
    (acc, pic) => ({
      positions: [...acc.positions, acc.totalWidth],
      totalWidth: acc.totalWidth + pic.width + 2 * PADDING,
      maxWidth: Math.max(acc.maxWidth, pic.width),
      maxHeight: Math.max(acc.maxHeight, pic.height)
    }),
    { positions: [], totalWidth: 0, maxWidth: 0, maxHeight: 0 }
  )
}
