/* @flow */

import React from 'react'
import type { ComponentType } from 'react'

import classNames from 'lib/class-names'
import { withHocDisplayName } from 'lib/display-name'

import styles from './styles.less'

type _Props = {
  +className?: string,
  +onMouseEnter?: () => void,
  +onMouseLeave?: () => void,
  +hidden?: boolean
}

type State = {
  visible: boolean
}

export default function withHiddenMeat<Props: {}> (
  Component: ComponentType<Props>
): ComponentType<Props & _Props> {
  class HiddenMeat extends React.Component<Props & _Props, State> {
    state: State
    onMouseEnter: () => void
    onMouseLeave: () => void

    constructor (props: any) {
      super(props)

      this.state = {
        visible: false
      }

      this.onMouseEnter = this._onMouseEnter.bind(this)
      this.onMouseLeave = this._onMouseLeave.bind(this)
    }

    _onMouseEnter (...args) {
      if (this.props.onMouseEnter) {
        this.props.onMouseEnter(...args)
      }
      this.setState({
        visible: !this.props.hidden
      })
    }

    _onMouseLeave (...args) {
      if (this.props.onMouseLeave) {
        this.props.onMouseLeave(...args)
      }
      this.setState({
        visible: false
      })
    }

    render () {
      const { className, onMouseEnter, onMouseLeave, ...rest } = this.props
      return (
        <Component
          className={classNames(
            className,
            styles.hiddenMeat,
            this.state.visible && styles.visibleMeat
          )}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          {...rest}
        />
      )
    }
  }

  return withHocDisplayName(HiddenMeat, Component)
}
