/* @flow */

import React from 'react'

import ImageReplacement from 'components/image-replacement'
import type { SizedImage } from 'types'

export type Props = SizedImage & {
  +title: string,
  +href: string
}

export default ({ title, href, src, width, height }: Props) => (
  <a href={href}>
    <ImageReplacement src={src} width={width} height={height}>
      {title}
    </ImageReplacement>
  </a>
)
