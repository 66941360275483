/* @flow */
/* global Element, $Diff */

import React from 'react'
import type { ComponentType } from 'react'

import { withHocDisplayName } from 'lib/display-name'
import { requestAnimationFrame } from 'lib/request-animation-frame'

type State = {
  clientWidth: number,
  clientHeight: number
}

export default function withSize<Props: State> (
  Component: ComponentType<Props>
): ComponentType<$Diff<Props, State>> {
  class WithSize extends React.Component<$Diff<Props, State>, State> {
    state: State
    onResize: void => void
    _isResizing: boolean
    _container: ?Element

    constructor () {
      super()
      this.state = {
        clientWidth: 0,
        clientHeight: 0
      }
      this.onResize = this._onResize.bind(this)
      this._isResizing = false
    }

    componentDidMount () {
      window.addEventListener('resize', this.onResize, false)
      this.calcSize()
    }

    componentWillUnmount () {
      window.removeEventListener('resize', this.onResize)
    }

    _onResize () {
      if (this._isResizing) {
        return
      }
      this._isResizing = true
      setTimeout(
        () =>
          requestAnimationFrame(() => {
            this._isResizing = false
            this.calcSize()
          }),
        0
      )
    }

    calcSize () {
      if (!this._container) {
        return
      }
      const { clientWidth, clientHeight } = this._container
      this.setState({
        clientWidth,
        clientHeight
      })
    }

    render () {
      return (
        <Component
          ref={c => {
            // $FlowFixMe
            this._container = c
          }}
          {...this.props}
          {...this.state}
        />
      )
    }
  }

  return withHocDisplayName(WithSize, Component)
}
